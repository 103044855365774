import React from 'react'
import { Helmet } from 'react-helmet'

const OpenSourceProjects = () => {
  return (
    <div className='page--open-source-projects wrapper'>
      <Helmet title={`Open Source Projects | Couchbase Developer Portal`} />
      <h1 id='open-source-projects-on-couchbase'>
        <span>Open Source Projects </span>
        <aside className='inline-block font-normal'>on Couchbase</aside>
      </h1>
      <p>
        Open Source at Couchbase is instrumental for our users, below are the
        many projects that will aid you in getting started with the technology
        of your choice.
      </p>

      <div className='flex flex-col xs:flex-col sm:flex-col md:flex-row lg:flex-row'>
        <div className='w-full xs:w-full sm:w-3/4 md:w-2/3 lg:w-2/3 mr-24'>
          <h2 id='couchbase-server'>Couchbase Server</h2>
          <p>
            The core server of the Couchbase distributed JSON document database.
          </p>
          <p>
            Couchbase Server is built from multiple components, each hosted in
            their own git repository. We use the{' '}
            <a href='https://source.android.com/setup/develop'>repo tool</a> to
            manage these multiple repositories and how they should be built. A
            repo manifest file defines how the source code combines to become an
            individual release.
          </p>
        </div>
        <div className='w-full xs:w-full sm:w-1/4 md:w-1/3 lg:w-1/3'>
          <h3 id='server-resources'>Server Resources</h3>
          <ul className='link-list'>
            <li>
              <a href='https://forums.couchbase.com/c/couchbase-server'>
                Forums
              </a>
            </li>
            <li>
              <a href='https://github.com/couchbase/manifest'>Source Code</a>
            </li>
            <li>
              <a href='https://issues.couchbase.com/browse/MB'>Issue Tracker</a>
            </li>
            <li>
              <a href='http://review.couchbase.org/'>Code Review</a>
            </li>
            <li>
              <a href='https://www.couchbase.com/downloads#Couchbase_Server'>
                Download
              </a>
            </li>
            <li>
              <a href='https://docs.couchbase.com/home/server.html'>
                Documentation
              </a>
            </li>
          </ul>
        </div>
      </div>

      <hr className='mt-4' />

      <h2 id='couchbase-server-sdks'>Couchbase Server SDKs</h2>
      <div className='w-full'>
        <div
          className='grid grid-flow-row auto-rows-max grid-cols
          xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'
        >
          <div>
            <h3 id='java-sdk'>Kotlin SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://github.com/couchbase/couchbase-jvm-clients/tree/master/kotlin-client'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/KCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/q/status:open+project:couchbase-jvm-clients,n,z'>
                  Code Review
                </a>
              </li>
              <li>
                <a
                  href='https://docs.couchbase.com/kotlin-sdk/current/hello-world/overview.html'>
                  Download
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/kotlin-sdk/current/hello-world/overview.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='java-sdk'>Java SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://github.com/couchbase/couchbase-jvm-clients/tree/master/java-client'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/JCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/q/status:open+project:couchbase-java-client,n,z'>
                  Code Review
                </a>
              </li>
              <li>
                <a
                  href='https://docs.couchbase.com/java-sdk/current/project-docs/sdk-full-installation.html'>
                  Download
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/java-sdk/current/hello-world/overview.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='java-sdk'>Scala SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://github.com/couchbase/couchbase-jvm-clients/tree/master/scala-client'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/SCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/q/status:open+project:couchbase-java-client,n,z'>
                  Code Review
                </a>
              </li>
              <li>
                <a
                  href='https://docs.couchbase.com/scala-sdk/current/hello-world/start-using-sdk.html'>
                  Download
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/scala-sdk/current/hello-world/start-using-sdk.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='dotnet-sdk'>.NET SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/category/net-sdk'>
                  Forums
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-net-client'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/NCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/q/status:open+project:couchbase-net-client,n,z'>
                  Code Review
                </a>
              </li>
              <li>
                <a
                  href='https://docs.couchbase.com/dotnet-sdk/current/project-docs/sdk-release-notes.html'>
                  Download
                </a>
              </li>
              <li>
                <a
                  href='https://docs.couchbase.com/dotnet-sdk/current/hello-world/overview.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='nodejs-sdk'>Node.js SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/category/node-js-sdk'>
                  Forums
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchnode'>Source Code</a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/JSCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/q/status:open+project:couchnode,n,z'>
                  Code Review
                </a>
              </li>
              <li>
                <a
                  href='https://docs.couchbase.com/nodejs-sdk/current/project-docs/sdk-release-notes.html'>
                  Download
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/nodejs-sdk/current/hello-world/overview.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='php-sdk'>PHP SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/category/php-sdk'>
                  Forums
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/php-couchbase'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/PCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/q/status:open+project:php-couchbase'>
                  Code Review
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/php-sdk/current/hello-world/start-using-sdk.html'>
                  Install
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/php-sdk/current/hello-world/overview.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='python-sdk'>Python SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/category/python-sdk'>
                  Forums
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-python-client'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/PYCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/q/status:open+project:couchbase-python-client,n,z'>
                  Code Review
                </a>
              </li>
              <li>
                <a
                  href='https://docs.couchbase.com/python-sdk/current/project-docs/sdk-full-installation.html'>
                  Download
                </a>
              </li>
              <li>
                <a
                  href='https://docs.couchbase.com/python-sdk/current/hello-world/overview.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='ruby-sdk'>Ruby SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/category/ruby-sdk'>
                  Forums
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-ruby-client'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/RCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/q/status:open+project:couchbase-ruby-client,n,z'>
                  Code Review
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/ruby-sdk/current/hello-world/overview.html'>
                  Download
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/ruby-sdk/current/hello-world/overview.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='c-cplusplus-sdk'>C/C++ SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/c/c-sdk'>Forums</a>
              </li>
              <li>
                <a href='https://github.com/couchbase/libcouchbase'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/CCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/q/status:open+project:libcouchbase,n,z'>
                  Code Review
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/c-sdk/current/hello-world/start-using-sdk.html'>
                  Download
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/c-sdk/current/hello-world/overview.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='go-sdk'>Go SDK</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/c/go-sdk'>Forums</a>
              </li>
              <li>
                <a href='https://github.com/couchbase/gocb'>Source Code</a>
              </li>
              <li>
                <a href='https://issues.couchbase.com/browse/GOCBC'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='http://review.couchbase.org/#/admin/projects/gocb'>
                  Code Review
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/go-sdk/current/project-docs/sdk-full-installation.html'>
                  Download
                </a>
              </li>
              <li>
                <a href='https://docs.couchbase.com/go-sdk/current/hello-world/overview.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='jdbc-driver'>JDBC Driver</h3>
            <ul className='link-list'>
              <li>
                <a href='https://github.com/jdbc-json/jdbc-cb'>Source Code</a>
              </li>
              <li>
                <a href='https://github.com/jdbc-json/jdbc-cb/issues'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='https://github.com/jdbc-json/jdbc-cb'>Code Review</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <hr className='mt-4' />

      <h2 id='couchbase-mobile'>Couchbase Mobile</h2>
      <p>
        A native document database for mobile and embedded devices and
        components to sync to Couchbase Server.
      </p>

      <h2 id='couchbase-lite'>Couchbase Lite</h2>
      <p>
        Store and sync JSON documents on iOS, Android (as well as other Java SE
        environments) and .NET environments. Below are the projects used in
        conjunction with Couchbase Lite for various platforms and languages.
      </p>

      <div className='w-full'>
        <div
          className='grid grid-flow-row auto-rows-max grid-cols
          xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'
        >
          <div>
            <h3 id='ios'>iOS</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/c/mobile'>Forums</a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-lite-ios'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-lite-ios/issues'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='https://www.couchbase.com/nosql-databases/downloads'>
                  Download
                </a>
              </li>
              <li>
                <a
                  href='https://developer.couchbase.com/documentation/mobile/current/get-started/couchbase-lite-overview/index.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='android'>Android</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/c/mobile'>Forums</a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-lite-android'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-lite-android/issues'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='https://www.couchbase.com/nosql-databases/downloads'>
                  Download
                </a>
              </li>
              <li>
                <a
                  href='https://developer.couchbase.com/documentation/mobile/current/get-started/couchbase-lite-overview/index.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='dotnet'>.NET</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/c/mobile'>Forums</a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-lite-net'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-lite-net/issues'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='https://www.couchbase.com/nosql-databases/downloads'>
                  Download
                </a>
              </li>
              <li>
                <a
                  href='https://developer.couchbase.com/documentation/mobile/1.2/get-started/couchbase-lite-overview/index.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='java'>Java</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/c/mobile'>Forums</a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-lite-java-native'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/couchbase-lite-java-native/issues'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='https://www.couchbase.com/nosql-databases/downloads'>
                  Download
                </a>
              </li>
              <li>
                <a
                  href='https://developer.couchbase.com/documentation/mobile/1.2/get-started/couchbase-lite-overview/index.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='phonegap'>PhoneGap</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/c/mobile'>Forums</a>
              </li>
              <li>
                <a href='https://github.com/couchbaselabs/Couchbase-Lite-PhoneGap-Plugin'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbaselabs/Couchbase-Lite-PhoneGap-Plugin/issues'>
                  Issue Tracker
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 id='sync-gateway'>Sync Gateway</h3>
            <ul className='link-list'>
              <li>
                <a href='https://forums.couchbase.com/c/mobile'>Forums</a>
              </li>
              <li>
                <a href='https://github.com/couchbase/sync_gateway'>
                  Source Code
                </a>
              </li>
              <li>
                <a href='https://github.com/couchbase/sync_gateway/issues'>
                  Issue Tracker
                </a>
              </li>
              <li>
                <a href='https://www.couchbase.com/nosql-databases/downloads'>
                  Download
                </a>
              </li>
              <li>
                <a
                  href='https://developer.couchbase.com/documentation/mobile/1.2/develop/guides/sync-gateway/index.html'>
                  Documentation
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <hr className='mt-4' />

      <h2 id='how-to-contribute-code'>How to contribute code</h2>
      <p>
        Whether you have a fix for a typo in a component, a bugfix, or a new
        feature, we'd love to collaborate.
      </p>
      <p>
        It takes a lot of work to get from a potential new bug fix or feature
        idea to well-tested shipping code, and we'd want to help you get there.
      </p>
      <div>
        <h3 id='steps-for-contributing-to-a-project'>
          Steps for Contributing to a Project
        </h3>
        <ol className='list-decimal'>
          <li>
            <p>
              Sign the Contributor License Agreement <sup>1</sup>:
              <ol className='list-decimal'>
                <li>
                  <a href='http://review.couchbase.org/'>
                    Join the code review site
                  </a>
                  .
                </li>
                <li>Log into the review site.</li>
                <li>
                  <a href='http://review.couchbase.org/#/settings/agreements'>
                    Fill out the agreement
                  </a>{' '}
                  under <em>Settings &gt; Agreements</em>.
                </li>
              </ol>
            </p>
          </li>
          <li>
            <p>Familiarize yourself with the build and test procedures.</p>
            <p>
              Some projects use a{' '}
              <a href='https://help.github.com/articles/using-pull-requests/'>
                GitHub pull request process
              </a>
              . Other projects use a{' '}
              <a href='https://www.couchbase.com/wiki/display/couchbase/Contributing+Changes'>
                code review process
              </a>
              , where one of our engineers will look at your change and run
              tests to verify your fix.
            </p>
            <p>
              Each Couchbase open source project lists a forum contact, where
              you can ask for help with the technical aspects of getting a patch
              accepted. Please reach out to ensure you're not duplicating
              effort.
            </p>
          </li>
        </ol>
      </div>

      <h2 id='how-to-report-a-bug'>How to report a bug</h2>
      <p>
        If you come across a bug or something unintuitive about Couchbase,
        please let us know.
      </p>
      <p>
        First, please find the relevant issue tracker for the part of Couchbase
        where you found the bug and then read the guidelines on how to{' '}
        <a href='https://blog.couchbase.com/contribute-to-open-source-projects/'>
          format bug reports
        </a>
        .
      </p>
      <p>
        For immediate help, please{' '}
        <a href='https://support.couchbase.com/hc/en-us'>
          visit our support page
        </a>
        .
      </p>
      <div>
        <h3>HOW TO REPORT A BUG OR GIVE USABILITY FEEDBACK</h3>
        <ol className='list-decimal'>
          <li>
            <p>
              Please read the{' '}
              <a href='https://blog.couchbase.com/contribute-to-open-source-projects/'>
                guidelines for what makes a useful bug report
              </a>
              . Some of these also apply to giving usability feedback.
            </p>
          </li>
          <li>
            <p>
              Find the appropriate project on this page and navigate to its
              issue tracker &mdash; and file your report.
            </p>
          </li>
        </ol>
      </div>

      <hr className='mt-4' />

      <h2 id='community-project'>Featured Projects</h2>

      <p>
        If you would like to add a project to this list, please email{' '}
        <a href='mailto:devadvocates@couchbase.com'>
          devadvocates@couchbase.com
        </a>
        .
      </p>
      <div className='mb-8'>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <h3 id='community-java'>Java</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://camel.apache.org/components/3.15.x/couchbase-component.html'>
                    Apache Camel
                  </a>
                </li>
                <li>
                  <a href='https://www.jhipster.tech/using-couchbase/'>
                    JHipster with Couchbase
                  </a>
                </li>
                <li>
                  <a href='https://github.com/micronaut-projects/micronaut-couchbase'>
                    Micronaut Couchbase
                  </a>
                </li>
                <li>
                  <a href='https://github.com/mumoshu/play2-memcached'>
                    Scala Play Framework Cache
                  </a>
                </li>
                <li>
                  <a href='https://spring.io/projects/spring-data-couchbase'>
                    Spring Data Couchbase
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-dotnet'>.NET</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='http://akka.net/'>Akka.NET</a>
                </li>
                <li>
                  <a href='https://github.com/couchbaselabs/Linq2Couchbase'>
                    Linq 2 Couchbase
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbaselabs/SignalR-Couchbase-Database-Example'>
                    SignalR
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-nodejs'>Node.js</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://ottomanjs.com/'>Ottoman</a>
                </li>
              </ul>
            </div>
            <h3 id='community-golang'>Golang</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/blevesearch/bleve'>Bleve</a>
                </li>
              </ul>
            </div>
            <h3 id='community-analytics'>Analytics</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://docs.databricks.com/_static/notebooks/couchbase.html'>
                    Apache Spark Notebook
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-data-migration'>Data Migration, Integrations & Backup</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/apache/nifi/tree/master/nifi-nar-bundles/nifi-couchbase-bundle'>
                    Apache Nifi
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbase/couchbase-cli'>
                    cbbackup and cbrestore
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbase/couchbase-elasticsearch-connector'>
                    Elastic Search
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbase/kafka-connect-couchbase'>
                    Kafka
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbase/couchbase-spark-connector'>
                    Spark
                  </a>
                </li>
                <li>
                  <a href='https://github.com/mgroves/SqlServerToCouchbase'>
                    SQL Server to Couchbase
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-connectors'>Connectors</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://docs.couchbase.com/server/current/connectors/odbc-jdbc-drivers.html'>
                    ODBC and JDBC Drivers
                  </a>
                </li>
                <li>
                  <a href='https://docs.couchbase.com/elasticsearch-connector/current/index.html'>
                    Elasticsearch Plug-in
                  </a>
                </li>
                <li>
                  <a href='https://docs.couchbase.com/kafka-connector/current/index.html'>
                    Kafka Connector
                  </a>
                </li>
                <li>
                  <a href='https://docs.couchbase.com/spark-connector/current/index.html'>
                    Spark Connector
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-ruby'>Ruby</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/doctolib/couchbase-orm'>
                    Doctolib ORM
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-php'>PHP</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/doctrine/KeyValueStore'>
                    Doctrine
                  </a>
                </li>
                <li>
                  <a href='https://github.com/friendsofcat/laravel-couchbase'>
                    Laravel
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3 id='community-schema-management'>Schema Management</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/differentway/couchmove'>
                    couchmove
                  </a>
                </li>
                <li>
                  <a href='https://github.com/brantburnett/couchbase-index-manager'>
                    couchbase-index-manager
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='sdks-and-orms'>SDKs and ORMs</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/couchbaselabs/Couchbase.Extensions'>
                    .NET Core Extensions
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbaselabs/couchbase-aspnet'>
                    ASP.NET Integration
                  </a>
                </li>
                <li>
                  <a href='https://github.com/SchwarzIT/crystal-map'>
                    Crystal Map
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbaselabs/Linq2Couchbase'>
                    Linq2Couchbase
                  </a>
                </li>
                <li>
                  <a href='http://ottomanjs.com/'>
                    Ottoman
                  </a>
                </li>
                <li>
                  <a href='https://github.com/spring-projects/spring-data-couchbase'>
                    Spring Data
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbaselabs/spring-session-data-couchbase/tree/master'>
                    Spring Session
                  </a>
                </li>
                <li>
                  <a href='https://github.com/zeroturnaround/sql-formatter'>
                    sql-formatter
                  </a>
                </li>
                <li>
                  <a href='https://github.com/Kashoo/synctos'>
                    synctos Sync Function
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-cli'>CLIs</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/couchbaselabs/couchbase-mobile-tools/blob/master/README.cblite.md'>
                    Couchbase-Lite-CLI
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbaselabs/couchbase-shell'>
                    Couchbase Shell or cbsh
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-test-data-integration'>Test Data Generation</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/brantburnett/couchbasefakeit'>
                    couchbasefakeit FakeIt Docker
                  </a>
                </li>
                <li>
                  <a href='https://github.com/brianfrankcooper/YCSB/tree/master/couchbase2'>
                    YCSB
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-load-testing-tools'>Load Testing Tools</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/couchbaselabs/meep-meep'>
                    MeepMeep - .NET workload gen
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbase/libcouchbase/blob/master/doc/cbc-n1qlback.markdown'>
                    N1QLBack
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-deployment-containers-k8s'>Deployment: Containers and K8s</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://hub.docker.com/_/couchbase'>
                    Docker Hub for Couchbase
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbase-partners/helm-charts'>
                    Helm Charts for Couchbase
                  </a>
                </li>
              </ul>
            </div>
            <h3 id='community-mobile'>Mobile</h3>
            <div>
              <ul className='link-list'>
                <li>
                  <a href='https://github.com/cbl-dart/cbl-dart'>
                    Couchbase Lite for Dart and Flutter
                  </a>
                </li>
                <li>
                  <a href='https://github.com/couchbase-examples/cordova-cblite-userprofile'>
                    Cordova Plugin
                  </a>
                </li>
                <li>
                  <a href='https://ionicframework.com/docs/v3/native/couchbase-lite/'>
                    Ionic Framework
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenSourceProjects
